<script setup lang="ts">
import SettingBannerWideItem, {
  SettingBannerWideItemAttrs,
} from '~~/utils/models/SettingBannerWideItem';

const props = defineProps<{
  item: SettingBannerWideItemAttrs;
  lazyImage?: boolean;
}>();
const banner$ = computed(() => new SettingBannerWideItem(props.item));
</script>

<template>
  <picture>
    <source
      media="(max-width: 639px)"
      :srcset="`
        ${banner$.getPortraitThumbnailURL(375, 480)},
        ${banner$.getPortraitThumbnailURL(750, 960)} 2x
      `"
    />
    <source
      :srcset="`
        ${banner$.getThumbnailURL(1440, 732)},
        ${banner$.getThumbnailURL(2880, 1464)} 2x
      `"
    />
    <img
      class="w-full h-full object-cover"
      width="375"
      height="480"
      :alt="banner$.data.caption"
      :loading="lazyImage ? 'lazy' : 'eager'"
      :src="banner$.getThumbnailURL(375, 480)"
    />
  </picture>
</template>
